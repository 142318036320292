// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acercade-js": () => import("./../src/pages/Acercade.js" /* webpackChunkName: "component---src-pages-acercade-js" */),
  "component---src-pages-design-empaque-js": () => import("./../src/pages/Design/Empaque.js" /* webpackChunkName: "component---src-pages-design-empaque-js" */),
  "component---src-pages-design-fotografia-js": () => import("./../src/pages/Design/Fotografia.js" /* webpackChunkName: "component---src-pages-design-fotografia-js" */),
  "component---src-pages-design-marca-js": () => import("./../src/pages/Design/Marca.js" /* webpackChunkName: "component---src-pages-design-marca-js" */),
  "component---src-pages-design-video-js": () => import("./../src/pages/Design/Video.js" /* webpackChunkName: "component---src-pages-design-video-js" */),
  "component---src-pages-develop-alexa-js": () => import("./../src/pages/Develop/Alexa.js" /* webpackChunkName: "component---src-pages-develop-alexa-js" */),
  "component---src-pages-develop-chat-bot-js": () => import("./../src/pages/Develop/ChatBot.js" /* webpackChunkName: "component---src-pages-develop-chat-bot-js" */),
  "component---src-pages-develop-data-base-js": () => import("./../src/pages/Develop/DataBase.js" /* webpackChunkName: "component---src-pages-develop-data-base-js" */),
  "component---src-pages-develop-excel-js": () => import("./../src/pages/Develop/Excel.js" /* webpackChunkName: "component---src-pages-develop-excel-js" */),
  "component---src-pages-develop-mobile-js": () => import("./../src/pages/Develop/Mobile.js" /* webpackChunkName: "component---src-pages-develop-mobile-js" */),
  "component---src-pages-develop-web-js": () => import("./../src/pages/Develop/Web.js" /* webpackChunkName: "component---src-pages-develop-web-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tecnologias-js": () => import("./../src/pages/Tecnologias.js" /* webpackChunkName: "component---src-pages-tecnologias-js" */)
}

